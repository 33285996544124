import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import {auth, signInWithGoogle} from '../../firebase';
import { NavLink, useNavigate } from 'react-router-dom';
import {Button, Container, makeStyles, Paper, TextField, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
    },
    paper: {
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Ширина формы
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(2),
    },
}));

const Login = () => {
    const navigate = useNavigate();
    const classes = useStyles();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Успешный вход
                const user = userCredential.user;
                navigate('/home');
                console.log(user);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorCode, errorMessage);
            });
    };

    const handleSignInWithGoogle = async () => {
        try {
            await signInWithGoogle();
            // Вход с помощью Google выполнен успешно
            navigate('/home');
        } catch (error) {
            // Обработка ошибки, если вход не выполнен
            console.error('Error signing in with Google:', error);
        }
    };

    return (
        <div className={classes.root}>
            <Container component="main" maxWidth="xs">
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        ResearchAI
                    </Typography>
                    <form className={classes.form}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={onLogin}
                        >
                            Login
                        </Button>
                    </form>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSignInWithGoogle}
                    >
                        Sign in with google
                    </Button>
                    <Typography variant="body2">
                        No account yet?{' '}
                        <NavLink to="/signup">Sign up</NavLink>
                    </Typography>
                </Paper>
            </Container>
        </div>
    );
};

export default Login;
