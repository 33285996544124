import React from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import {useNavigate} from 'react-router-dom';
import BackgroundTest from '../../assets/img/vector-art.jpg';
import {AppBar, Button, Container, makeStyles, Paper, Toolbar, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
    },
    title: {
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    img: {
        borderRadius: '50%',
        width: '100px',
        height: '100px',
    },
    card: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));

const Home = ({ user }) => {
    const navigate = useNavigate();
    const classes = useStyles();

    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                navigate('/');
                console.log('Signed out successfully');
            })
            .catch((error) => {
                console.error('Error signing out:', error);
            });
    };

    return (
        <div className={classes.root} style={{ backgroundImage: `url(${BackgroundTest})` }}>
            <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none' }}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Welcome Home
                    </Typography>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleLogout}>
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>
            <div className={classes.card}>
                <Container component="main" maxWidth="xs">
                    <Paper className={classes.paper}>
                        {user ? (
                            <>
                                <Typography variant="h6">здарова заебал, {user.displayName}</Typography>
                                <img src={user.photoURL} alt="" className={classes.img} />
                            </>
                        ) : (
                            <p>Guest</p>
                        )}
                    </Paper>
                </Container>
            </div>
        </div>
    );
};

export default Home;