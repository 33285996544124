// ...

import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBLyalJPU_a7Y5-mnVLi5mALXbERs_MAHs",
    authDomain: "researchai-709ad.firebaseapp.com",
    projectId: "researchai-709ad",
    storageBucket: "researchai-709ad.appspot.com",
    messagingSenderId: "756984520148",
    appId: "1:756984520148:web:3300d90371afbd2f34ca41"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => signInWithPopup(auth, provider);
