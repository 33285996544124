import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import Home from './page/Home';
import Signup from './page/Signup';
import Login from './page/Login';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Импорт функций из Firebase v9

const theme = createTheme();

const App = () => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const auth = getAuth(); // Инициализация аутентификации

        onAuthStateChanged(auth, (user) => {
            setUser(user);
        });
    }, []);

    console.log(user);

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <CssBaseline />
                <Routes>
                    <Route path="/home" element={<Home user={user} />} />
                    <Route path="/" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
};

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
